import './application-links.scss';
import { ApplicationLinksButton } from './application-links-button/application-links-button';
import {
  applicationLinksItem,
  prodApplicationLinksItem,
} from './application-links-items';
import { isProduction } from '../../services/env.service';

export const ApplicationLinks = () => {
  const links = isProduction()
    ? prodApplicationLinksItem
    : applicationLinksItem;
  return (
    <div className="application-links-container">
      <div className="application-links-wrapper">
        {links &&
          links.map((link, i) => {
            return (
              <ApplicationLinksButton
                url={link.url}
                logo={link.logo}
                title={link.title}
                color={link.color}
                key={i}
              />
            );
          })}
      </div>
    </div>
  );
};
