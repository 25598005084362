import axios from 'axios';
import jsSHA from 'jssha';
import { useSearchParams } from 'react-router-dom';
import { GetEnvironmentSettings } from './env.service';

export const tokenik = {
  token: '',
};

export const authUrlOptions = {
  dev: 'https://at-dev-306-at-app.azurewebsites.net',
  devToken: 'https://at-dev-305-at-app.azurewebsites.net',
  staging: 'https://authst.airtrade.nl',
  stagingToken: 'https://03-55-01-101.airtrade.nl',
  prod: 'https://auth.airtrade.com',
  prodToken: 'https://01-55-01-101.airtrade.com',
};

export const redirectUiOptions = {
  currentPath: `${window.location.origin}${window.location.pathname}`,
};

export const authVerifierParams = {
  state: Math.random().toString(36).substring(7),
  verifier: Math.random().toString(36).substring(2),
};

const clientID = 'EXTRANETNL';

export const returnCodeChallenge = () => {
  const codeChallenge = new jsSHA('SHA-256', 'TEXT');
  codeChallenge.update(authVerifierParams.verifier);
  sessionStorage.setItem('verifier', authVerifierParams.verifier);
  return encodeURIComponent(codeChallenge.getHash('B64'));
};

export const login = () => {
  const url = `${
    GetEnvironmentSettings().authUrl
  }/login?response_type=code&client_id=${clientID}&redirect_uri=${
    GetEnvironmentSettings().redirectUrl
  }&state=${
    authVerifierParams.state
  }&code_challenge=${returnCodeChallenge()}&code_challenge_method=S256`;
  window.open(url, '_self');
};

export const getLookupParametersFromUrl = () => {
  const urlSearchParams = new URLSearchParams(document.location.search);
  const reference = urlSearchParams.get('Reference')?.replace(/[^0-9]/g, '');
  const anvr = urlSearchParams.get('Anvr');
  const id = urlSearchParams.get('Id');

  return { reference, anvr, id };
};
export const storeLookupParameters = () => {
  const fetchedUrlParameters = getLookupParametersFromUrl();
  if (
    fetchedUrlParameters.reference &&
    fetchedUrlParameters.anvr &&
    fetchedUrlParameters.id
  ) {
    localStorage.setItem(
      'lookupDetails',
      JSON.stringify({
        anvr: fetchedUrlParameters.anvr,
        reference: fetchedUrlParameters.reference,
        id: fetchedUrlParameters.id,
      })
    );
  }
};

export const getTokenFromApi = async (code) => {
  try {
    const url = `${GetEnvironmentSettings().authToken}/token`;
    const payload = {
      grant_Type: 'authorization_code',
      client_id: clientID,
      code,
      code_Verifier: sessionStorage.getItem('verifier'),
      redirect_uri: `${GetEnvironmentSettings().redirectUrl}`,
    };
    const result = await axios.post(url, payload);
    setSession(result.data.token);
    return result.data.token;
  } catch (e) {
    console.error(`Authorization error: ${e}`);
    if (code && !sessionStorage.getItem('token')) {
      window.location.href = '/';
    }
  }
};

export const getSessionToken = (searchParams) => {
  let token;
  if (searchParams.get('code') && !token) {
    token = getTokenFromApi(searchParams.get('code'));
  } else if (sessionStorage.getItem('token')) {
    token = sessionStorage.getItem('token');
    tokenik.token = token;
  } else if (!searchParams.get('code') && !sessionStorage.getItem('token')) {
    token = null;
  }
  return token;
};

export const setSession = (userToken) => {
  sessionStorage.setItem('token', JSON.stringify(userToken));
};

export const logout = () => {
  localStorage.clear();
  sessionStorage.clear();

  const url = `${GetEnvironmentSettings().authUrl}/logout?redirect_uri=${
    window.location.origin
  }`;

  window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
      window.location.reload();
    }
  });
  window.open(url, '_self');
};
