import { useEffect, useState } from 'react';
import {
  convertLangToCulture,
  getMarkdownText,
} from '../../../services/helper.service';
import './application-links-button.scss';

export const ApplicationLinksButton = ({ url, logo, title, color }) => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setReady(true), 2500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="application-links-button-container">
      {ready && (
        <a href={url} target="_blank" rel="noreferrer">
          <div className="application-links-button">
            <img src={`/${logo}`} alt="" height="30" width="30" />
            <div
              className={color}
              dangerouslySetInnerHTML={getMarkdownText(title)}
            />
          </div>
        </a>
      )}
    </div>
  );
};
