import { UrlPathsEnum } from "../../enums/url-paths";

export const applicationLinksItem = [
  {
    url: `https://ff.airtradedev.com/${UrlPathsEnum.login}`,
    logo: "logos/ff_logo.png",
    title: "Fare**finder**",
    color: "primary",
  },
  {
    url: `https://myff.airtradedev.com/${UrlPathsEnum.login}`,
    logo: "logos/ff_logo.png",
    title: "My **Fare**finder",
    color: "primary",
  },
];

export const prodApplicationLinksItem = [
  {
    url: `https://www.farefinder.nl/${UrlPathsEnum.login}`,
    logo: "logos/ff_logo.png",
    title: "Fare**finder**",
    color: "primary",
  },
  {
    url: `https://my.farefinder.nl/${UrlPathsEnum.login}`,
    logo: "logos/ff_logo.png",
    title: "My **Fare**finder",
    color: "primary",
  },
];
